const cats = [
  {
    name: 'Meowskin',
    image:
      'https://images.unsplash.com/photo-1472491235688-bdc81a63246e?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=05297d6a133548da4eac65f10cdfd37d&auto=format&fit=crop&w=700&q=60',
    age: 2,
    excerpt: 'professional mouse hounter',
    gender: 'male',
  },
  {
    name: 'Purrcy',
    image:
      'https://images.unsplash.com/photo-1529257414772-1960b7bea4eb?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=99a036a823f65958ab245848f5091dd7&auto=format&fit=crop&w=1350&q=80',
    age: 5,
    excerpt: 'Purring engaged',
    gender: 'female',
  },
  {
    name: 'Catzilla',
    image:
      'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=2d7b1bd980752bb3ea0a259f528eae78&auto=format&fit=crop&w=1350&q=80',
    age: 1,
    excerpt: 'Will trample over your life',
    gender: 'male',
  },
  {
    name: 'Catniss',
    image:
      'https://images.unsplash.com/photo-1516399779-1480b4f76df6?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=91d3888b79d5fd667ad03f5833e89d45&auto=format&fit=crop&w=1350&q=80',
    age: 7,
    excerpt: 'Your one true leader',
    gender: 'female',
  },
  {
    name: 'Fluffykins',
    image:
      'https://images.unsplash.com/photo-1511044568932-338cba0ad803?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=4ac33b3554f738537b33011883712d1c&auto=format&fit=crop&w=1350&q=80',
    age: 2,
    excerpt: 'Fun, fun, fluff',
    gender: 'male',
  },
  {
    name: 'Cat',
    image:
      'https://images.unsplash.com/photo-1513360371669-4adf3dd7dff8?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=6402d48f35584fbf36a874be1635374b&auto=format&fit=crop&w=1350&q=80',
    age: 19,
    excerpt: 'I hate kittens',
    gender: 'male',
  },
];

export default cats;
